<template>
    <div class="wrapper">
        <el-card style="margin-top:20px">
            <div slot="header" class="header">
                <div class="dict-title">
                    字典名称：
                    <el-tag type="success">{{ $route.query.dictName }}</el-tag>
                </div>
                <div>
                    <el-button type="primary" size="small" icon="el-icon-plus" @click="goAdd">添加</el-button>
                    <el-button type="info" size="small" icon="el-icon-back" @click="goBack">返回</el-button>
                </div>
            </div>
            <tp-table :tableData="dataItems" :columns="columns" :isPage="0"/>
        </el-card>
    </div>
</template>

<script>
import {getDictValues, delDictValue} from './api'

const columns = [
    {
        label: '名称',
        width: '200',
        prop: 'name',
        customRender(h, row) {
            return (
                <p class="one-line" title={row['name']}>
                    {row['name']}
                </p>
            )
        }
    },
    {
        label: '值',
        width: '300',
        prop: 'value',
        customRender(h, row) {
            return (
                <p class="one-line" title={row['value']}>
                    {row['value']}
                </p>
            )
        }
    },
    {
        label: '备注',
        width: '250',
        prop: 'remark',
        customRender(h, row) {
            return (
                <p class="one-line" title={row['remark']}>
                    {row['remark']}
                </p>
            )
        }
    },
    {
        label: '操作',
        customRender(h, row) {
            return (
                <div>
                    <el-button type="primary" disabled={row['isDefault'] == 1} size="mini" icon="el-icon-edit"
                               onClick={() => this.goEdit(row['id'])}>
                        编辑
                    </el-button>
                    <el-popconfirm title={`确定删除吗`} onConfirm={() => this.goRemove(row['id'])}>
                        <el-button disabled={row['isDefault'] == 1} style="margin-left:10px" slot="reference"
                                   type="danger" size="mini" icon="el-icon-delete">
                            删除
                        </el-button>
                    </el-popconfirm>
                </div>
            )
        }
    }
]
export default {
    data() {
        return {
            dataItems: [],
            columns
        }
    },
    provide() {
        return {
            context: this
        }
    },
    mounted() {
        console.log(this.$route, '@@@@@');
        this.queryDictValues(this.$route.query.dictCode)
    },
    methods: {
        goAdd() {
            this.$router.push({name: 'DictValueAddOrEdit', params: {dictCode: this.$route.query.dictCode}})
        },
        goEdit(id) {
            this.$router.push({
                name: 'DictValueAddOrEdit',
                params: {id: id || undefined, dictCode: this.$route.query.dictCode}
            })
            console.log(id)
        },
        async goRemove(id) {
            let res = await delDictValue(id)
            await this.queryDictValues(this.$route.query.dictCode)
        },
        goBack() {
            this.$router.back()
        },

        async queryDictValues(dictCode) {
            let res = await getDictValues(dictCode)
            this.dataItems = res.data || []
            console.log(this.dataItems)
        }
    }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;

  /deep/ .one-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
  }

  .dict-title {
    font-weight: bold;
  }

  .el-form {
    display: flex;
    justify-content: space-between;
    align-items: center;

    /deep/ .el-form-item {
      margin-bottom: 0;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
  }
}
</style>
